import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { ERROR, TAG_MODULE_PARTNER } from "@/core/helpers/constants";
import { IPartner } from "@/core/interfaces/partner.interface";
import { IParams } from "../../core/interfaces/user.interfaces";

export interface StoreInfo {
  partner: IPartner;
  listPartners: Array<IPartner>;
  totalPartner: number;
}

@Module
export default class PartnerModule extends VuexModule implements StoreInfo {
  partner = {} as IPartner;
  listPartners = [] as Array<IPartner>;
  totalPartner = 0;
  listPartnerKam = [] as Array<string>;

  /**
   * Get employee
   * @returns IPartner
   */
  get getPartner(): IPartner {
    return this.partner;
  }

  /**
   * Get current total employee
   * @returns IEmployee
   */
  get getListPartners(): Array<IPartner> {
    return this.listPartners;
  }

  /**
   * Get current employee list
   * @returns number
   */
  get getTotalPartner(): number {
    return this.totalPartner;
  }

  /**
   * Get current string list
   * @returns string
   */
  get getListPartnerKam(): Array<string> {
    return this.listPartnerKam;
  }

  @Mutation
  [Mutations.SET_PARTNER](data: IPartner) {
    this.partner = data;
  }

  @Mutation
  [Mutations.SET_LIST_PARTNER](data: Array<IPartner>) {
    // this.listPartners = data.filter(
    //   (item: IPartner) => item.status != "INACTIVE"
    // );
    this.listPartners = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_PARTNER](data: number) {
    this.totalPartner = data;
  }

  @Mutation
  [Mutations.SET_LIST_PARTNER_KAM](data: Array<string>) {
    this.listPartnerKam = data;
  }

  @Action
  [Actions.DETAIL_PARTNER](id: number) {
    ApiService.setHeader();
    return ApiService.get(`v1/partner/${id}`)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | DETAIL_PARTNER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_PARTNER, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | DETAIL_PARTNER | error | `,
          response
        );
        this.context.commit(Mutations.SET_PARTNER, {});
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.CREATE_PARTNER](payload: object) {
    ApiService.setHeader();
    return ApiService.post("v1/partner", payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | CREATE_PARTNER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | CREATE_PARTNER | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.UPDATE_PARTNER](payload: IParams) {
    ApiService.setHeader();
    return ApiService.patch(`v1/partner/${payload.id}`, payload.payload)
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | UPDATE_PARTNER | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | UPDATE_PARTNER | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_PARTNER](params: object) {
    ApiService.setHeader();
    return ApiService.query("/v1/partner", {
      params: params,
    })
      .then(({ data }) => {
        console.log(`${TAG_MODULE_PARTNER} | LIST_PARTNER | success | `, data);
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_PARTNER, data.content);
        this.context.commit(Mutations.SET_TOTAL_PARTNER, data.totalElements);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | LIST_PARTNER | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }

  @Action
  [Actions.LIST_PARTNER_KAM]() {
    ApiService.setHeader();
    return ApiService.get("/v1/partner/kam")
      .then(({ data }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | LIST_PARTNER_KAM | success | `,
          data
        );
        this.context.commit(Mutations.PURGE_ERROR);
        this.context.commit(Mutations.SET_LIST_PARTNER_KAM, data);
      })
      .catch(({ response }) => {
        console.log(
          `${TAG_MODULE_PARTNER} | LIST_PARTNER_KAM | error | `,
          response
        );
        this.context.commit(Mutations.SET_ERROR, response || ERROR);
      });
  }
}
